var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"max-width":"1200px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticStyle:{"border-radius":"8px","min-height":"320px"},attrs:{"flat":""}},[_c('v-card-title',{staticClass:"py-2 px-2",staticStyle:{"color":"teal","border-bottom":"1px solid teal"},attrs:{"dark":""}},[_c('v-btn',{staticClass:"text-none mr-2 mb-2",attrs:{"color":"teal","dark":""},on:{"click":function($event){return _vm.showCreateDept()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" Thêm phòng ban ")],1),(_vm.selected_depts && _vm.selected_depts.length > 0)?[_c('v-btn',{staticClass:"text-none mr-2 mb-2",attrs:{"color":"error","dark":""},on:{"click":_vm.deleteDepts}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-delete-forever")]),_vm._v(" Xóa ")],1)]:_vm._e(),_c('v-spacer'),_c('v-text-field',{staticClass:"mb-2",staticStyle:{"max-width":"320px"},attrs:{"append-icon":"mdi-magnify","label":"Search","clearable":"","outlined":"","dense":"","single-line":"","hide-details":""},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}})],2),_c('v-card-text',{staticClass:"pa-0 pt-2",staticStyle:{"min-height":"300px"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"flat":"","headers":_vm.headers,"items":_vm.depts,"loading":_vm.loading,"loading-text":"Loading... Please wait","no-data-text":"Chưa có phòng ban nào","item-key":"id","mobile-breakpoint":0,"items-per-page":_vm.pageSize,"show-select":"","dense":"","search":_vm.searchString,"page":_vm.depsPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.depsPage=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'/departments/' + item._id}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.createdDate).format("HH:mm DD/MM/YY"))+" ")]}},{key:"item.lock",fn:function(ref){
var item = ref.item;
return [(item.lock)?_c('v-icon',[_vm._v(" mdi-lock ")]):_vm._e()]}}]),model:{value:(_vm.selected_depts),callback:function ($$v) {_vm.selected_depts=$$v},expression:"selected_depts"}}),(_vm.pageCount > 1)?_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.depsPage),callback:function ($$v) {_vm.depsPage=$$v},expression:"depsPage"}})],1):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"width":"640"},model:{value:(_vm.email_dialog),callback:function ($$v) {_vm.email_dialog=$$v},expression:"email_dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"py-1",staticStyle:{"background-color":"teal","color":"white"}},[_vm._v(" Gửi email ")]),_c('v-card-text',{staticClass:"pa-4"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Tựa đề email"},model:{value:(_vm.email_title),callback:function ($$v) {_vm.email_title=$$v},expression:"email_title"}}),_c('vue-editor',{model:{value:(_vm.email_content),callback:function ($$v) {_vm.email_content=$$v},expression:"email_content"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.email_dialog = false}}},[_vm._v(" Hủy ")]),_c('v-btn',{attrs:{"text":"","color":"teal"},on:{"click":_vm.sendEmailToDept}},[_vm._v(" Gửi ")])],1)],1)],1),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","persistent":"","width":"900"},model:{value:(_vm.dialogEditDept),callback:function ($$v) {_vm.dialogEditDept=$$v},expression:"dialogEditDept"}},[_c('edit-department',{attrs:{"show":_vm.dialogEditDept,"dept":_vm.curEditDept},on:{"close-dialog-and-refresh-depts":function($event){_vm.dialogEditDept = false;
          _vm.fetchDepts();},"close-dialog":function($event){_vm.dialogEditDept = false}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }