<template>
  <div style="background-color: white;height: 100%;">
    <v-toolbar dark color="primary">
      <v-btn icon dark v-on:click="$emit('close-dialog')">
        <v-icon>close</v-icon>
      </v-btn>
      <v-toolbar-title v-if="dept._id">Cập Nhật Thông Tin Phòng Ban</v-toolbar-title>
      <v-toolbar-title v-else>Tạo Phòng Ban Mới</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container
      fluid
      flat
      grid-list-xs
      style="padding:2px;width:600px;height: auto;"
      v-bind:style="{ backgroundColor: $root.pageBackColor }"
    >
      <v-row align="center">
        <v-col class="ma-0 pa-2" cols="12">
          <div style="width: 500px;">
            <v-card flat class="pa-3 mt-4" style="border: 1px solid #AAAAAA;">
              <v-card-title class="px-4 py-1" v-if="!dept._id">
                Tạo Phòng Ban Mới
              </v-card-title>
              <v-card-title class="px-4 py-1"  v-else>
                Cập Nhật Thông Tin Phòng Ban
              </v-card-title>
              <v-card-text>
                <v-form
                  ref="editUserForm"
                  v-model="formValid"
                  :lazy-validation="true"
                >
                  <v-text-field
                    style="max-width:500px;margin-top:16px;margin-right:80px;"
                    v-model="dept.id"
                    :rules="[rules.required]"
                    label="Mã Phòng Ban *"
                    type="text"
                    outlined
                    dense
                  >
                  </v-text-field>
                  <v-text-field
                    style="max-width:500px;margin-top:16px;margin-right:80px;"
                    v-model="dept.name"
                    :rules="[rules.required]"
                    label="Tên Phòng Ban *"
                    type="text"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  class="text-capitalize bold"
                  color="grey darken-1"
                  @click.native="$emit('close-dialog')"
                  style="font-size: 16px;"
                  >Cancel</v-btn
                >

                <v-btn
                  class="text-capitalize bold"
                  text
                  color="primary"
                  @click.native="saveUser"
                  style="font-size: 16px;"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  // const moment = require("moment");
  import { mapGetters } from "vuex";

  export default {
    props: ["show", "dept"],
    computed: {
      ...mapGetters({
        userId: "userId",
        token: "token",
        role: "role",
        fullname: "getFullName",
      }),
    },
    data() {
      return {
        formValid: "",
        showPassword: false,
        rules: {
          required: (value) => !!value || "* Required",
        },
      };
    },
    watch: {
      show(val) {
        if (val) {
          setTimeout(() => {
            this.resetValidationForm();
          }, 50);
        } else {
          this.resetValidationForm();
        }
      },
    },
    methods: {
      saveUser() {
        if (!this.validateForm()) return;
        if (this.dept._id) {
          this.updateUser();
        } else {
          this.createNewUser();
        }
      },
      updateUser() {
        var self = this;
        var path = this.$root.apiUser + "/depts/update/" + self.dept._id;
        this.axios
          .put(path, self.dept, { headers: { "x-auth": self.token } })
          .then((response) => {
            if (response.data.status === "OK") {
              alert(
               "Update User " + self.dept.name + " success!"
                
              );
              self.$emit("close-dialog-and-refresh-depts");
            } else {
              alert( "Error: " + response.data.message)
            }
          });
      },
      createNewUser() {
        var self = this;
        var path = this.$root.apiUser + "/depts/create";
        self.dept.customer_id = this.$root.apiUserCustomId

        this.axios
          .post(path, self.dept, { headers: { "x-auth": self.token } })
          .then((response) => {
            if (response.data.status === "OK") {
              alert(
               "Create new dept " + self.dept.name + " success!"
              );
              self.$emit("close-dialog-and-refresh-depts");
            } else {
              alert("Error: " + response.data.message)
           
            }
          })
          .catch((err) => {
            alert( "Error: " + err.message)
          });
      },
      validateForm() {
        return this.$refs.editUserForm.validate();
      },
      resetForm() {
        this.$refs.editUserForm.reset();
      },
      resetValidationForm() {
        this.$refs.editUserForm.resetValidation();
      },
    },
  };
</script>
<style></style>
