<template>
  <v-container style="max-width:1200px;">
    <v-row>
      <v-col cols="12">
        <v-card flat style="border-radius:8px;min-height:320px;">
          <v-card-title
            class="py-2 px-2"
            dark
            style="color:teal;border-bottom: 1px solid teal;"
          >
            <v-btn
              color="teal"
              dark
              class="text-none mr-2 mb-2"
              @click="showCreateDept()"
            >
              <v-icon small class="mr-2">mdi-plus</v-icon>
              Thêm phòng ban
            </v-btn>
            <template v-if="selected_depts && selected_depts.length > 0">
              <!-- <v-btn
                color="#DD0011"
                dark
                class="text-none mr-2 mb-2"
                @click="lockDepts('locks')"
              >
                <v-icon small class="mr-2">mdi-lock-outline</v-icon>
                Khóa
              </v-btn>
              <v-btn
                color="green"
                dark
                class="text-none mr-2 mb-2"
                @click="lockDepts('unlocks')"
              >
                <v-icon small class="mr-2"
                  >mdi-lock-open-variant-outline</v-icon
                >
                Mở Khóa
              </v-btn> -->
              <v-btn
                color="error"
                dark
                class="text-none mr-2 mb-2"
                @click="deleteDepts"
              >
                <v-icon small class="mr-2">mdi-delete-forever</v-icon>
                Xóa
              </v-btn>
              <!-- <v-btn
                color="green"
                dark
                class="text-none mr-2"
                @click="showSendEmailDialog"
              >
                <v-icon small class="mr-2">
                  mdi-email
                </v-icon>
                Gửi email
              </v-btn> -->
            </template>
            <v-spacer />
            <v-text-field
            class="mb-2"
              v-model="searchString"
              append-icon="mdi-magnify"
              label="Search"
              clearable
              outlined
              dense
              single-line
              hide-details
              style="max-width:320px;"
            ></v-text-field>
          </v-card-title>

          <v-card-text class="pa-0 pt-2" style="min-height:300px;">
            <v-data-table
              flat
              v-model="selected_depts"
              :headers="headers"
              :items="depts"
              :loading="loading"
              loading-text="Loading... Please wait"
              no-data-text="Chưa có phòng ban nào"
              item-key="id"
              :mobile-breakpoint="0"
              :items-per-page="pageSize"
              show-select
              dense
              class="elevation-0"
              :search="searchString"
              :page.sync="depsPage"
              @page-count="pageCount = $event"
              hide-default-footer
            >
              <template v-slot:item.id="{ item }">
                <a :href="'/departments/' + item._id">
                  {{ item.id }}
                </a>
              </template>
              <template v-slot:item.createdDate="{ item }">
                {{ $moment(item.createdDate).format("HH:mm DD/MM/YY") }}
              </template>
              <template v-slot:item.lock="{ item }">
                <v-icon v-if="item.lock">
                  mdi-lock
                </v-icon>
              </template>
            </v-data-table>
            <div v-if="pageCount > 1" class="text-center pt-2">
              <v-pagination
                v-model="depsPage"
                :length="pageCount"
              ></v-pagination>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-dialog v-model="email_dialog" width="640">
        <v-card>
          <v-card-title style="background-color:teal;color:white" class="py-1">
            Gửi email
          </v-card-title>

          <v-card-text class="pa-4">
            <v-text-field
              outlined
              dense
              class=""
              label="Tựa đề email"
              v-model="email_title"
            ></v-text-field>

            <vue-editor v-model="email_content"></vue-editor>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn text @click="email_dialog = false">
              Hủy
            </v-btn>
            <v-btn text color="teal" @click="sendEmailToDept">
              Gửi
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogEditDept"
        fullscreen
        hide-overlay
        persistent
        width="900"
      >
        <edit-department
          :show="dialogEditDept"
          :dept="curEditDept"
          v-on:close-dialog-and-refresh-depts="
            dialogEditDept = false;
            fetchDepts();
          "
          v-on:close-dialog="dialogEditDept = false"
        />
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import EditDepartment from "@/components/Departments/EditDepartment.vue";

export default {
  computed: {
    ...mapGetters({
      token: "token",
    }),
  },
  components: {
    VueEditor,
    EditDepartment,
  },
  data() {
    return {
      roles: ['user-manager', 'admin'],
      depts: [],
      selected_depts: [],
      pageSize: 20,
      pageCount: 0,
      depsPage: 1,
      loading: false,
      searchString: "",
      headers: [
        {
          text: "Mã Phòng Ban",
          align: "start",
          sortable: true,
          value: "id",
          // width: 140,
        },
        {
          text: "Tên Phòng Ban",
          align: "start",
          sortable: true,
          value: "name",
          // width: 140,
        },
        {
          text: "Ngày tạo",
          align: "start",
          sortable: true,
          value: "createdDate",
          // width: 140,
        },
        {
          text: "Khóa",
          align: "start",
          sortable: true,
          value: "lock",
          width: 80,
        },
      ],
      email_title: "",
      email_content: "",
      email_dialog: false,
      dialogEditDept: false,
      curEditDept: {},
    };
  },
  methods: {
    showCreateDept() {
      console.log("showCreateDept");
      this.curEditDept = {
        id: "",
        name: "",
      };
      this.dialogEditDept = true;
    },
    editClient(item) {
      this.curEditDept = item;
      this.dialogEditDept = true;
    },
    fetchDepts() {
      let self = this;
      self.loading = true;
      self.selected_depts = [];
      self.axios
        .get(self.$root.apiUser + "/depts/list/" + self.$root.apiUserCustomId, {
          headers: { "x-auth": this.token },
        })
        // + 'owners='+self.$root.site_id
        .then((res) => {
          if (res.data.status == "OK") {
            self.depts = res.data.content.items;
            // self.depts = res.data.content;
          } else {
            console.log(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          self.loading = false;
        });
    },
    lockDepts(lockFunction) {
      // console.log(this.selected_depts)
      if (!this.selected_depts || this.selected_depts.length <= 0) return;
      let self = this;
      this.axios
        .put(
          self.$root.apiUser + "/depts/" + lockFunction,
          {
            site_id: this.$root.apiUserCustomId,
            ids: this.selected_depts.map((u) => u._id),
          },
          { headers: { "x-auth": this.token } }
        )
        .then((res) => {
          if (res.data.status == "OK") {
            self.fetchDepts();
          } else {
            console.log(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteDepts() {
      // console.log(this.selected_depts)
      if (confirm("Bạn có chắc muốn xóa các mục đã chọn ?")) {
        if (!this.selected_depts || this.selected_depts.length <= 0) return;
        let self = this;
        this.axios
          .put(
            self.$root.apiUser + "/depts/deletes",
            {
              site_id: this.$root.apiUserCustomId,
              ids: this.selected_depts.map((u) => u._id),
            },
            { headers: { "x-auth": this.token } }
          )
          .then((res) => {
            if (res.data.status == "OK") {
              alert("Xóa thành công!")
              self.fetchDepts();
            } else {
              console.log(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    showSendEmailDialog() {
      this.email_title = "";
      this.email_content = "";
      this.email_dialog = true;
    },
    sendEmailToDept() {
      if (!this.selected_depts || this.selected_depts.length <= 0) return;
      let self = this;
      this.axios
        .put(
          self.$root.coreUsrApi +
            "depts/sendEmail/" +
            this.selected_depts.map((u) => u._id).join(";"),
          { title: this.email_title, htmlContent: this.email_content },
          { headers: { "x-auth": this.token } }
        )
        .then((res) => {
          if (res.data.status == "OK") {
            self.email_dialog = false;
            alert("Đã gửi email xong");
          } else {
            console.log(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchDepts();
    });
  },
};
</script>

<style></style>
